import React from 'react'
import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import { rpaDetailView } from '../../../../../redux/actions/addOns/rpa/rpaAction'
import { useHistory } from 'react-router-dom'
import * as $ from 'jquery';
import RPASubmitHistory from './RPASubmitHistory'
import RPASubmitHistoryListing from './RPASubmitHistoryListing'

function RPAVerifyButton(props) {
  const { rpaConfig, handleVerifyInsurance, handleReVerifyInsurance, rpaEligibility, verify, pprops: { btnClasses, insuranceType, wrapperClasses, orderId, patient, order, insuranceVerifying, showVerifyBtn, isOrderView, isPatientView } } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const handleInactiveVerify = () => {
    history.push('/add-ons/rpa/dashboard')
    $('#orderForm-modal').modal('hide');
  }

  const expired_vision_insurance_logs = isOrderView ? order.patient_insurance_attributes?.expired_vision_insurance_logs : patient[insuranceType]?.expired_vision_insurance_logs

  return (
    <>
      <div className={`d-flex ${wrapperClasses}`}>
        <div className={`${isPatientView && 'py-4 text-center section1'}`}>
          {isPatientView && <h6 className='my-4 pb-4 border-bottom text-center px-5 font-size-14'>iTRUST Visionary</h6>}
          {rpaConfig?.status !== 'inactive' ?
            <>
              {rpaEligibility[insuranceType]?.success === undefined && <>
                {insuranceVerifying === insuranceType ?
                  <button className={`btn btn-primary btn-sm ${btnClasses} text-nowrap`} type='button'>
                    <span>Verifying...</span>
                    <div className="spinner-border verify-spinner ml-2" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </button> :
                  <>
                    {verify ?
                      <button className={`btn btn-primary btn-sm ${btnClasses}`} type='button' disabled={true}>
                        Verify
                      </button> :
                      <button className={`btn btn-primary btn-sm ${btnClasses}`} type='button' onClick={handleVerifyInsurance}>
                        Verify
                      </button>
                    }
                  </>
                }
              </>}
              {rpaEligibility[insuranceType] && <>
                <div className='text-nowrap'>
                  {showVerifyBtn && <>
                    {rpaEligibility[insuranceType]?.success ?
                      <>
                        <button type='button' className={`text-nowrap btn btn-success btn-sm ${btnClasses}`} onClick={() => dispatch(rpaDetailView({ visible: true, file_url: rpaEligibility[insuranceType]?.eligibility_file_url }))}>
                          Found <i className="las la-check-circle"></i>
                        </button>
                      </> :
                      <button type='button' className={`text-nowrap btn btn-danger custom-tooltip btn-sm ${btnClasses}`} data-title={`Error: ${rpaEligibility[insuranceType].error}`}>
                        Not Found <i className="las la-times-circle"></i>
                      </button>
                    }
                  </>}
                  {!showVerifyBtn && <button type='button' className={`btn btn-primary d-flex align-items-center text-nowrap ml-2 btn-sm ${btnClasses}`} title='Retry' disabled={verify} onClick={handleReVerifyInsurance}>
                    Re-Verify <i className="la la-sync font-size-14 ml-2" />
                  </button>}
                </div>
              </>}
            </> :
            <button type='button' className={`btn btn-primary btn-sm ${btnClasses}`} onClick={handleInactiveVerify}>
              Verify
            </button>
          }
        </div>
        {isPatientView && <div className={`py-3 ${expired_vision_insurance_logs?.length ? 'section2' : 'w-75'}`}>
          <RPASubmitHistoryListing dispatch={dispatch} expired_vision_insurance_logs={expired_vision_insurance_logs?.length ? [expired_vision_insurance_logs[0]] : []} wrapperClasses="patient-verify-history" />
        </div>}
        {(expired_vision_insurance_logs && expired_vision_insurance_logs.length > 0) && <div className={`${isPatientView && 'py-4 text-center section3'}`}>
          {isPatientView && <h6 className='my-4 pb-4 border-bottom text-center px-5 font-size-14'>History</h6>}
          <RPASubmitHistory
            expired_vision_insurance_logs={expired_vision_insurance_logs}
            dispatch={dispatch}
          />
        </div>}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { rpa, organisation: { organisation } } = state

  return {
    rpaEligibility: rpa.eligibility,
    verify: rpa.verify,
    rpaConfig: organisation.add_ons_config?.type?.rpa
  }
}

export default connect(mapStateToProps)(RPAVerifyButton)
import React from 'react'
import { callingInformationUpdate } from '../../../../../../redux/actions/addOns/voip/voipActions';

export default function OutgoingButtons(props) {
  const { call, dispatch, handleMakeOutGoingCall, isAnonymous, contact, callInfo, isCallActive, telecomServiceType } = props

  const hangUpOutgoingCall = async () => {
    telecomServiceType === 'bandwidth' ? await call.terminate() : await call.disconnect()
    dispatch(callingInformationUpdate({ call: call, callStatus: 'call_ended', isCallActive: false }))
    console.log("Call HangUp.")
  }

  const handleOutgoingCall = () => {
    let phoneNumber;
    if (contact?.id) {
      phoneNumber = contact.cell_phone || contact.home_phone || contact.office_phone
      handleMakeOutGoingCall(phoneNumber)
    }
    if (isAnonymous) {
      if (callInfo?.call_log?.id) {
        // call Log number consider
        phoneNumber = callInfo.call_log.call_type === "outgoing" ? callInfo.call_log.to : callInfo.call_log.from
        handleMakeOutGoingCall(phoneNumber)
      }
      // call log patient contact 
    }
  }

  return (
    <>
      {/* {(call?.status() === 'closed' || !call?.status()) && */}
      {(callInfo?.callStatus === 'call_ended' || !callInfo?.callStatus) &&
        <button className={`btn p-0 rounded-circle mr-5 font-size-20 ${isCallActive && 'disabled'}`} onClick={() => handleOutgoingCall()}>
          <i className="las la-phone border border-primary rounded-circle text-primary p-2 pointer"></i>
        </button>
      }
      {/* {(call?.status() === 'connecting' || call?.status() === 'open' || call?.status() === 'reconnecting' || call?.status() === 'ringing') && */}
      {(callInfo?.callStatus === 'call_started' || callInfo?.callStatus === 'ringing') &&
        <button className='btn p-0 rounded-circle mr-5 font-size-20' onClick={hangUpOutgoingCall}>
          <i className="las la-phone-alt border border-danger rounded-circle rotate-45 text-danger p-2 pointer"></i>
        </button>
      }
    </>
  )
}

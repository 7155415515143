import React from 'react';
import { Route, Switch, Redirect} from 'react-router-dom';
import asyncComponent from '../../../components/AsyncComponent';

const EyeglassLensType = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/eyeglassLensType/EyeglassLensType')
)
const EyeglassLensMaterial = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/eyeglassLensMaterial/EyeglassLensMaterial')
)
const EyeglassLensFrame = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/eyeglassLensFrame/EyeglassLensFrame')
)
const EyeglassAddOns = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/eyeglassAddOn/EyeglassAddOn')
)
const MiscItems = asyncComponent(() =>
  import('../../../containers/authorised/setting/order/inventory/miscItems/MiscItems')
)

const OrderRoutes = (
  <Switch>
    <Route exact path="/settings/order-inventory/eyeglass-lens-type" component={EyeglassLensType} />
    <Route exact path="/settings/order-inventory/eyeglass-lens-material" component={EyeglassLensMaterial} />
    <Route exact path="/settings/order-inventory/eyeglass-lens-frame" component={EyeglassLensFrame} />
    <Route exact path="/settings/order-inventory/eyeglass-add-ons" component={EyeglassAddOns} />
    <Route exact path="/settings/order-inventory/misc-items" component={MiscItems} />
    <Redirect exact from="/settings/order-inventory" to="/settings/order-inventory/eyeglass-lens-type" />
  </Switch>
);

export default OrderRoutes;
import React from 'react'
import WhatsAppAdvertisement from '../WhatsAppAdvertisement'
import { connect, useDispatch } from 'react-redux';
import WhatsAppPopup from './WhatsAppPopup';
import WhatsAppActivationScreens from '../WhatsAppActivationScreens';
import PatientProfileAddOns from '../../PatientProfileAddOns';

function WhatsAppPopupWrap(props) {
  const { whatsAppConfig, whatsAppProfileInfo, whatsAppAccountInfo } = props
  const dispatch = useDispatch()

  return (
    <div className='whatsapp-popup'>
      {whatsAppConfig?.status && whatsAppConfig?.status !== 'inactive' ?
        <>
          {(whatsAppProfileInfo?.phone_number_id && whatsAppAccountInfo?.account_status !== 'not_verified') ?
            <WhatsAppPopup /> :
            <WhatsAppActivationScreens isPopupScreen={true} dispatch={dispatch} />
          }
        </> :
        <div className='whatsapp-popup-wrap'>
          <PatientProfileAddOns
            btnClasses="text-primary bg-transparent border-0 font-size-20 py-0"
            wrapperClasses="justify-content-between"
            isPopUp={true}
            type="whatsapp"
            hideMinimizeBtn={true}
            hideAddOnsBtn={true}
          />
          <WhatsAppAdvertisement isPopupScreen={true} />
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation }, whatsApp } = state

  return {
    whatsAppConfig: organisation.add_ons_config?.type?.whatsapp,
    whatsAppProfileInfo: whatsApp.profile_info,
    whatsAppAccountInfo: whatsApp.account_info,
  }
}

export default connect(mapStateToProps)(WhatsAppPopupWrap)
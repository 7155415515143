import Toastr from "../../../itrust_common/components/Toastr"
const DEFAULT_STATE = {
  revenue_orders: [],
  meta: { pagination: {} },
  saving: false
}
let changes = null;
export default function revenueOrderReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let revenueOrders = [];
  let updatedRecord = {};
  switch(action.type){
    case 'REVENUE_ORDERS_LOAD_PENDING':
      return {...state, saving: true};

    case 'REVENUE_ORDER_UPDATE_PENDING':
      return {...state, saving: true};

    case 'SEND_ORDER_PENDING':
      return {...state, saving: true};

    case 'REVENUE_ORDERS_LOAD_REJECTED':
    case 'REVENUE_ORDER_UPDATE_REJECTED':
    case 'SEND_ORDER_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false
      }
      return {...state,...changes};

    case 'REVENUE_ORDERS_LOAD_FULFILLED':
      changes = {
        ...response,
        saving: false
      }
      return {...state,...changes};

    case 'SEND_ORDER_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = {
        saving: false
      }
      return {...state, ...changes};

    
    case 'REVENUE_ORDER_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.contact_lens_order;
      changes = {
        revenue_order: updatedRecord,
        saving: false,
        editing: false
      };
      return { ...state, ...changes };

    case 'REVENUE_ORDER_DELETE_FULFILLED':
      revenueOrders = [...state.revenue_orders];
      for (const [index, revenueOrder] of revenueOrders.entries()) {
        if (revenueOrder.id === response.revenue_order.id) {
          revenueOrders.splice(index, 1);
        }
      }
      return {
        ...state,
        revenue_orders: revenueOrders,
        error: null
      };

    default:
      return state;
  }
}
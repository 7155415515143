import React from 'react'
import voipCallingImg from '../../../../assets/static/images/voip_calling.svg'
import voipCallingFeatureImg from '../../../../assets/static/images/voip_features.svg'
import voipCallingMobileImg from '../../../../assets/static/images/voip_calling_mobile.svg'
import AdvertisementWrap from '../AdvertisementWrap'

export default function VoipAdvertisement(props) {
  const { isPopupScreen } = props

  const voipFeature = [
    'Never miss a patient‘s phone call with our voip phone system.',
    "Our Phone POP feature keeps your office up to speed on the patient with real time patient.",
    'Flexibility for your business to use a virtual phone, physical phone or both.',
    'Have your own dedicated business phone line using your current or new number.',
  ]

  const voipAdvDetail = {
    title: 'iTRUST Voice Calling',
    redirectTo: 'https://www.itrust.io',
    imageAddOn: voipCallingImg,
    imageFeatures: voipCallingFeatureImg,
    isPopupScreenAddOnImg: voipCallingMobileImg,
    features: voipFeature
  }

  return (
    <AdvertisementWrap addOnAdvDetail={voipAdvDetail} isPopupScreen={isPopupScreen} />
  )
}

import { API_VERSION_ONE, handleAPI } from "../../../../itrust_common/utils/apiUtils";

export function voipCallLogsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/voip/call_logs`;
  const method = 'GET'

  return {
    type: 'VOIP_CALL_LOGS_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

export function voipReportsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/voip/voip_dashboard`;
  const method = 'GET'

  return {
    type: 'VOIP_PHONE_REPORT_LOAD',
    payload: handleAPI(url, filters, method)
  };
}

import Toastr from "../../../components/Toastr"
const parent = 'SETTING_'
const DEFAULT_STATE = {
  anonymous_calendar_setting: {}
}
let changes = null;
export default function anonymousBookingReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  let updatedRecord = {};
  switch(action.type){
    case `${parent}ANONYMOUS_BOOKING_LOAD_PENDING`:
      return {...state, saving: false};

    case `${parent}ANONYMOUS_BOOKING_UPDATE_PENDING`:
      return {...state, saving: true};

    case `${parent}ANONYMOUS_BOOKING_LOAD_REJECTED`:
    case `${parent}ANONYMOUS_BOOKING_UPDATE_REJECTED`:
      Toastr.handleShow(response.data.success, response.data.error, response.status_code)
      changes = {
        saving: false
      }
      return { ...state, ...changes};

    case `${parent}ANONYMOUS_BOOKING_LOAD_FULFILLED`:
      changes = {
        ...response,
        saving: false
      }
      return {...state,...changes};
    
    case `${parent}ANONYMOUS_BOOKING_UPDATE_FULFILLED`:
      Toastr.handleShow(true, response.meta.message, 201)
      updatedRecord = response.anonymous_calendar_setting;
      changes = {
        anonymous_calendar: {...updatedRecord},
        saving: false
      };
      return { ...state, ...changes };

    default:
      return state;
  }
}
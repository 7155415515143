import React, { Component } from 'react';
import { FieldArray, reduxForm, change, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import * as $ from 'jquery';

import GlassForm from './eyeglass/GlassForm';
import LabOrderButton from './eyeglass/labOrder/LabOrderButton';
import LabOrderModal from './eyeglass/labOrder/LabOrderModal';
import ImportWrapper from './import/ImportWrapper';

import { orderCloseModal } from '../../../../redux/actions/order/orderActions';
import DuplicateLensOrGlassButton from '../../buttons/DuplicateLensOrGlassButton';


class RenderEyeglasses extends React.Component {

  importFinalGlass(member, index, data) {
    const { pprops } = this.props
    pprops.dispatch(change('orderEyeglassForm', `${member}[od_glass_details][sphere]`, data.od_sphere))
    pprops.dispatch(change('orderEyeglassForm', `${member}[od_glass_details][cylinder]`, data.od_cylinder))
    pprops.dispatch(change('orderEyeglassForm', `${member}[od_glass_details][axis]`, data.od_axis))
    pprops.dispatch(change('orderEyeglassForm', `${member}[od_glass_details][add]`, data.od_add))
    pprops.dispatch(change('orderEyeglassForm', `${member}[od_glass_details][prism]`, data.od_prism))
    pprops.dispatch(change('orderEyeglassForm', `${member}[os_glass_details][sphere]`, data.os_sphere))
    pprops.dispatch(change('orderEyeglassForm', `${member}[os_glass_details][cylinder]`, data.os_cylinder))
    pprops.dispatch(change('orderEyeglassForm', `${member}[os_glass_details][axis]`, data.os_axis))
    pprops.dispatch(change('orderEyeglassForm', `${member}[os_glass_details][add]`, data.os_add))
    pprops.dispatch(change('orderEyeglassForm', `${member}[os_glass_details][prism]`, data.od_prism))
    $(`#examFinalGlass-Modal${index}`).modal('hide')
  }

  render() {
    const { fields, pprops, order } = this.props
    return (
      <div className="row">
        <div className={`${order.status === 'finalized' && 'disabled'} col-12 mb-7`}>
          <div className="d-flex justify-content-between align-items-center">
            <h5>Eyeglass Order</h5>
            <div className="d-flex align-items-center">
              <ImportWrapper order={order} importName="eyeglass" />
              <button type="button" className="btn btn-primary ml-5" onClick={() => { fields.push({}); setTimeout(() => pprops.submit(), 500) }}>
                Add New
              </button>
            </div>
          </div>
        </div>

        {fields.map((member, index, members) => (
          <div key={index} className="col-12 mb-7">
            <div className="bg-white shadow-sm rounded">
              <div className="row mx-0">
                <div className="col-12 p-5 border-bottom border-bottom-2 border-light">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className={`${order.status === 'finalized' && 'disabled'}`}>
                      Eyeglass Order #{index + 1}
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className={`${order.status === 'finalized' && 'disabled'}`}>
                        <DuplicateLensOrGlassButton orderId={order.id} lensOrGlassName='eyeglass' id={eval(`order.${member}?.id`)}>
                          <button className={`btn btn-outline-primary mx-3`} aria-disabled="true">Duplicate</button>
                        </DuplicateLensOrGlassButton>
                        {
                          members.get(index).is_on_hold ?
                            <button type="button" className="btn btn-warning mx-3"
                              onClick={() => { pprops.dispatch(change('orderEyeglassForm', `${member}[is_on_hold]`, false)); setTimeout(() => pprops.submit(), 500) }}
                            >
                              Unhold Order
                            </button>
                            :
                            <button type="button" className="btn btn-outline-primary mx-3"
                              onClick={() => { pprops.dispatch(change('orderEyeglassForm', `${member}[is_on_hold]`, true)); setTimeout(() => pprops.submit(), 500) }}
                            >
                              Hold Order
                            </button>
                        }
                        {order.exam_examination_id &&
                          <button onClick={() => $(`#examFinalGlass-Modal${index}`).modal()} type="button" className="btn btn-outline-primary mx-3" >
                            Final Rx
                          </button>
                        }
                      </div>
                      <LabOrderButton eyeglassId={members.get(index).id} labOrderId={members.get(index).eyeglass_lab_order_id}>
                        <button type="button" className="btn btn-outline-primary mx-3" >
                          Lab Order
                        </button>
                      </LabOrderButton>
                      <button type="button" className={`${order.status === 'finalized' && 'disabled'} btn btn-outline-danger ml-3`}
                        onClick={() => { pprops.dispatch(change('orderEyeglassForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}>
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
                <div className={`${order.status === 'finalized' && 'disabled'}`}>
                  {members.get(index).id &&
                    <GlassForm
                      key={`glass-id${members.get(index).id}`}
                      {...this.props}
                      currentEyeglass={members.get(index)}
                      member={member}
                    />
                  }
                </div>
              </div>
            </div>
            <LabOrderModal eyeglassId={members.get(index).id} orderId={order.id} />
            {this.renderExamLensModal(member, index)}
          </div>
        ))}
      </div>
    );
  }

  renderExamLensModal(member, index) {
    const { exam } = this.props
    return (
      <div className="inner-modal">
        <div className="modal fade" id={`examFinalGlass-Modal${index}`} tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content border-0 rounded shadow-lg">
              <div className="modal-header border-bottom border-bottom-2 border-light">
                <h5>Finalized Glass Prescriptions</h5>
                <button type="button" className="btn btn-outline-primary" onClick={() => $(`#examFinalGlass-Modal${index}`).modal('hide')}>
                  Close
                </button>
              </div>
              <div className="modal-body px-0 py-5">
                {
                  exam.id && exam.finalized_glasses_attributes.map((finalizedGlass, index) =>
                    <div className="overview-table mb-7">
                      <table className="table table-hover shadow-none">
                        <thead>
                          <tr className="text-dark border-bottom border-bottom-2 border-light bg-light">
                            <th scope="col">{index + 1}</th>
                            <th scope="col" colSpan="5">Issue Date: {exam.issue_date}</th>
                            <th scope="col">
                              <button className="btn btn-primary px-3 py-2" onClick={() => this.importFinalGlass(member, index, finalizedGlass)}>
                                Import
                              </button>
                            </th>
                          </tr>
                          <tr className="text-dark border-bottom border-bottom-2 border-white bg-light">
                            <th scope="col"></th>
                            <th scope="col">Eye</th>
                            <th scope="col">SPH</th>
                            <th scope="col">CYL</th>
                            <th scope="col">Axis</th>
                            <th scope="col">Add</th>
                            <th scope="col">Prism</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-dark border-bottom border-bottom-2 border-white bg-light">
                            <td></td>
                            <td>OD</td>
                            <td>{finalizedGlass.od_sphere}</td>
                            <td>{finalizedGlass.od_cylinder}</td>
                            <td>{finalizedGlass.od_axis}</td>
                            <td>{finalizedGlass.od_add}</td>
                            <td>{finalizedGlass.od_prism}</td>
                          </tr>
                          <tr className="text-dark border-bottom border-bottom-2 border-white bg-light">
                            <td></td>
                            <td>OS</td>
                            <td>{finalizedGlass.os_sphere}</td>
                            <td>{finalizedGlass.os_cylinder}</td>
                            <td>{finalizedGlass.os_axis}</td>
                            <td>{finalizedGlass.os_add}</td>
                            <td>{finalizedGlass.os_prism}</td>
                          </tr>
                          <tr className="text-dark border-bottom border-bottom-2 border-white bg-light">
                            <td></td>
                            <td className="font-weight-bold">Recommendations</td>
                            <td colSpan="5">
                              {
                                finalizedGlass.recommendations && Object.keys(finalizedGlass.recommendations).join(", ")
                              }
                            </td>
                          </tr>
                          <tr className="text-dark border-bottom border-bottom-2 border-white bg-light">
                            <td></td>
                            <td className="font-weight-bold">Comments</td>
                            <td colSpan="5">
                              {finalizedGlass.notes}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


class EyeglassForm extends Component {

  componentWillUnmount() {
    if (this.props.dirty) {
      this.props.handleSubmit();
      this.props.dispatch(orderCloseModal(false))
    }
  }

  render() {
    const { handleSubmit, order, exam, onSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <FieldArray name="eyeglasses_attributes"
          props={{
            order: order,
            exam: exam,
            onSubmit: onSubmit,
            pprops: this.props
          }}
          component={RenderEyeglasses} />
      </form>
    )
  }
}


EyeglassForm = reduxForm({
  form: 'orderEyeglassForm',
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if (props.dirty) {
      dispatch(orderCloseModal(true))
    }
  }
})(EyeglassForm)

const selector = formValueSelector('orderEyeglassForm')

EyeglassForm = connect(state => {
  const { order: { order }, exam: { exam } } = state
  const currentEyeglassAttributes = selector(state, 'eyeglasses_attributes')
  return {
    exam: exam,
    order: order,
    currentEyeglassAttributes,
    initialValues: {
      id: order.id,
      eyeglasses_attributes: order.eyeglasses_attributes
    }
  }
})(EyeglassForm)

export default EyeglassForm;

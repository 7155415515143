import React from 'react'
import Alert from '../../../itrust_common/components/Alert'
import { claimFormCreate } from '../../../redux/actions/order/claimFormActions'
import { orderLoad } from '../../../redux/actions/order/orderActions'
import { rpaClaimFormCreate, rpaClaimFormLoad } from '../../../redux/actions/addOns/rpa/rpaAction'
import { connect } from 'react-redux';
import { getInsuranceFromRPACompanies, isRpaVerifiedInsurance } from '../../../containers/authorised/addOns/AddOnsHelperFunctions'

function GenerateClaimFormButton(props) {
  const { dispatch, order, handleTabChange, rpaConfig, companies } = props
  const isRpaSupported = isRpaVerifiedInsurance(rpaConfig, companies, order.patient_insurance_attributes?.insurance_name)

  const handleGenerateClaimForm = () => {
    if (order.insurance_receivable <= 0.0) {
      Alert.confirm("This order has a receivable less than or equal to $0 and creating a 1500 claim form could lead to a claim rejection.", (res) => {
        if (res) {
          dispatch(handleClaimFormCreate)
        }
      })
    }
    else {
      handleClaimFormCreate()
    }
  }

  const handleClaimFormCreate = async () => {
    if (!order.patient_insurance_id)
      Alert.warning("Patient insurance is not selected yet, You can't generate 1500 form without patient insurance")
    else {
      await claimFormGenerate()
      await dispatch(orderLoad(order.id, { include: '*' }));
      handleTabChange(6)
    }
  }

  const claimFormGenerate = async () => {
    if (isRpaSupported) {
      const insurance = getInsuranceFromRPACompanies(companies, order.patient_insurance_attributes?.insurance_name)
      await dispatch(rpaClaimFormCreate({ orders_order_id: order.id, patient_id: order.patient_id, insurance_type: order.patient_insurance_attributes?.insurance_type, store_id: order.store_id, insurance_payer: order.patient_insurance_attributes?.insurance_name, service: insurance.service, other_details: { resource: "generate_claim" } }))
      await dispatch(rpaClaimFormLoad(order.id))
      return;
    }
    else {
      await dispatch(claimFormCreate(order.id))
      return;
    }
  }

  return (
    <>
      <button className="btn btn-danger mx-lg-3" onClick={handleGenerateClaimForm}>Generate Claim Form</button>
    </>
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation }, rpa: { companies } } = state
  return {
    rpaConfig: organisation.add_ons_config?.type?.rpa,
    companies,
  }
}

export default connect(mapStateToProps)(GenerateClaimFormButton)
import React from 'react'
import { formatPhoneNumber, humanize } from '../../../itrust_common/components/HelperFunctions'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

export default function NotificationsListing(props) {
  const { notifications, handleMessageView, logsRoute, handleNotificationRead } = props

  return (
    notifications.map(notification => <div className='d-flex flex-row align-items-baseline border-bottom dropdown-item p-0'>
      <span className={`circle-5 ml-5 mr-2 ${!notification.read_at && 'bg-primary rounded-circle'}`}></span>
      <span className='w-100 px-0 py-4'>
        <span className='d-flex justify-content-between font-size-14 px-2'>
          <span className='font-weight-500'>{notification.activity_attributes.activity_type === "patient_sms_received" ? 'Text message received' : humanize(notification.activity_attributes.activity_type)}</span>
          <ActionDropdown notification={notification} handleNotificationRead={handleNotificationRead} />
        </span>
        {notification.activity_attributes.parameters.patient_id ?
            handleMessageView ? <p className="text-wrap m-0 mb-4">
              <Link to={`/patients/${notification.activity_attributes.parameters.patient_id}/overview`} className="text-primary">{notification.activity_attributes.parameters.patient_name}</Link><small className='mx-2'>{formatPhoneNumber(notification.activity_attributes.parameters.from)}</small>
              <span className='text-primary pointer' onClick={() => handleMessageView(notification.activity_attributes.parameters.patient_id)}> view</span>
            </p> :
              <div className='mb-4'>
                <Link to={`/patients/${notification.activity_attributes.parameters.patient_id}/overview`} className="text-primary">
                  {notification.activity_attributes.parameters.patient_name}
                </Link>
                <small className='mx-3'>{formatPhoneNumber(notification.activity_attributes.parameters.from)}</small>
                <Link to={logsRoute} className="text-primary ml-2">view</Link>
              </div> :
            handleMessageView ? <p className="text-wrap m-0 mb-4">
              Anonymous<small className='mx-2'>{formatPhoneNumber(notification.activity_attributes.parameters.from)}</small>
              <Link to={logsRoute} className="text-primary ml-2">view</Link>
            </p> :
              <span className='mb-4'>
                <small>
                  Anonymous
                  <p className='ml-3'>{formatPhoneNumber(notification.activity_attributes.parameters.from)}</p>
                </small>
                <Link to={logsRoute} className="text-primary ml-2">view</Link>
              </span>
        }
        <span className='ml-1 text-secondary'>{notification.activity_attributes.created_at}</span>
      </span >
    </div>
    )
  )
}

const ActionDropdown = (props) => {
  const { notification, handleNotificationRead } = props
  return (
    <div className="dropdown notification-nested-dropdown bg-transparent">
      <a className="p-0 font-size-20" role="button" id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false">
        <i className="las la-ellipsis-v pr-3"></i>
      </a>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
        <button className='dropdown-item' onClick={() => handleNotificationRead(notification.id, notification.read_at)}>{notification.read_at ? "Mark as unread" : "Mark as read"}</button>
      </div>
    </div>
  )
}
import React from 'react'
import PatientProfileAddOns from '../../PatientProfileAddOns'
import PatientInfoHeader from '../../PatientInfoHeader'

export default function WhatsAppChatHeader(props) {
  const { patient } = props

  return (
    <>
      <PatientProfileAddOns
        btnClasses="text-primary bg-transparent border-0 font-size-20 py-0"
        wrapperClasses="justify-content-between"
        isPopUp={true}
        type="whatsapp"
        patient={patient}
      />
      <PatientInfoHeader patient={patient} />
    </>
  )
}

import React, { useState } from 'react'
import PatientContact from './PatientContact'
import CallLogs from './logs/CallLogs'
import Dialer from './Dialer'
import { useDispatch } from 'react-redux'
import { filterReset } from '../../../../../../itrust_common/redux/actions/filterActions'

export default function StoreCallingWrapper(props) {
  const { makeOutGoingCall, isCallActive } = props
  const [activeDialerTab, setActiveDialerTab] = useState(1)
  const dispatch = useDispatch()

  return (
    <div>
      <div className='bg-white'>
        {activeDialerTab === 1 &&
          <Dialer
            makeOutGoingCall={(phoneNumber) => makeOutGoingCall(phoneNumber)}
            isCallActive={isCallActive}
          />
        }
        {activeDialerTab === 2 && <PatientContact />}
        {activeDialerTab === 3 && <CallLogs />}
      </div>
      <div className='d-flex justify-content-around font-size-12 py-3'>
        <button className={`border-0 bg-transparent d-flex flex-column align-items-center ${activeDialerTab === 2 && 'text-primary'}`} onClick={() => { setActiveDialerTab(2); dispatch(filterReset()) }}>
          <i className="las la-user-plus font-size-20"></i>
          Patient
        </button>
        <button className={`border-0 bg-transparent d-flex flex-column align-items-center ${activeDialerTab === 1 && 'text-primary'}`} onClick={() => setActiveDialerTab(1)}>
          <i className="las la-user-plus font-size-20"></i>
          Keypad
        </button>
        <button className={`border-0 bg-transparent d-flex flex-column align-items-center ${activeDialerTab === 3 && 'text-primary'}`} onClick={() => setActiveDialerTab(3)}>
          <i className="lar la-clock font-size-20"></i>
          Logs
        </button>
      </div>
    </div>
  )
}

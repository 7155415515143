import React from 'react'
import StoreCallingWrapper from './store/StoreCallingWrapper'
import PatientCallWrapper from './patient/PatientCallWrapper'
import { callPopUpEdit, callingInformationUpdate } from '../../../../../redux/actions/addOns/voip/voipActions'
import { useDispatch } from 'react-redux'
import Alert from '../../../../../itrust_common/components/Alert'
import PatientIncoming from './patient/PatientIncoming'
import { bandwidthOutGoingCallConnect, bandwidthClientSetup } from './BandwidthCallingHelpers'
import { twilioClientSetup, TwilioOutGoingCallConnect } from './TwilioCallingHelpers'
import { addOnLoad, addOnsLoad } from '../../../../../redux/actions/addOns/addOnAction'

export default function OutGoingWrapper(props) {
  const { callPopUpStatus, currentStoreTelecomConfig, isCallActive, contact, callInfo, telecomServiceType, isCallRecordingActive } = props
  const dispatch = useDispatch()

  const makeOutGoingCall = async (phoneNumber) => {
    dispatch(callingInformationUpdate({ isCallActive: true }))
    const storeId = localStorage.getItem('StoreID');

    if (storeId && currentStoreTelecomConfig?.[telecomServiceType]?.phone_number) {
      if (phoneNumber) {
        await handleMakeOutgoingCall(storeId, phoneNumber)
      }
      else {
        Alert.warning("Please enter a valid phone Number.")
      }
    }
    else {
      if (!currentStoreTelecomConfig?.twilio?.phone_number) {
        Alert.warning("Selected store doesn't have PhoneNumber.")
      }
      else {
        Alert.warning("Please select an store.")
      }
      dispatch(callingInformationUpdate({ isCallActive: false }))
    }
  }

  const handleMakeOutgoingCall = async (storeId, phoneNumber) => {
    const data = { store_id: storeId, to_number: `${phoneNumber}`, patient_id: contact.id }
    if (telecomServiceType === 'bandwidth') {
      await handleAddOnLoad()
      let res = await bandwidthClientSetup(data, dispatch, isCallRecordingActive)
      await bandwidthOutGoingCallConnect(res, phoneNumber, false, dispatch)
    }
    else {
      let res = await twilioClientSetup(data, dispatch)
      const callRes = await TwilioOutGoingCallConnect(res, phoneNumber, dispatch)
      dispatch(callingInformationUpdate({ call: callRes }))
    }
    dispatch(callPopUpEdit('patient'))
  }

  const handleAddOnLoad = async () => {
    let res = await dispatch(addOnsLoad({ category: "voip" }))
    const currentAddOnId = res.value.data.add_ons[0].id;
    dispatch(addOnLoad(currentAddOnId, { include: '*' }))
  }

  return (
    <div>
      {callPopUpStatus === "store_calling" &&
        <StoreCallingWrapper
          makeOutGoingCall={(phoneNumber) => makeOutGoingCall(phoneNumber)}
          telecomServiceType={telecomServiceType}
          isCallActive={isCallActive}
        />
      }
      {callPopUpStatus === 'patient' &&
        <>
          {(callInfo.isIncomingCall && callInfo?.callStatus === 'ringing') ?
            <PatientIncoming
              makeOutGoingCall={phoneNumber => makeOutGoingCall(phoneNumber)}
              telecomServiceType={telecomServiceType}
              isCallActive={isCallActive}
              dispatch={dispatch}
            /> :
            <PatientCallWrapper
              makeOutGoingCall={phoneNumber => makeOutGoingCall(phoneNumber)}
              telecomServiceType={telecomServiceType}
              isCallActive={isCallActive}
              dispatch={dispatch}
            />
          }
        </>
      }
      {telecomServiceType === "bandwidth" && <>
        <video autoPlay id='remote-video-container' className='d-none'></video>
      </>}
    </div>
  )
}

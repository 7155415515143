import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery';

import { orderEdit, orderLoad } from '../../../redux/actions/order/orderActions';
import { examLoad, examUpdate } from '../../../redux/actions/exam/examActions';
import { patientReportsLoad } from '../../../itrust_common/redux/actions/settingActions/patientReportActions';
import { claimFormLoad } from '../../../redux/actions/order/claimFormActions';
import { staffsLoad } from '../../../redux/actions/staffActions';
import {tabChange} from '../../../itrust_common/redux/actions/otherActions';

import { currentStoreLoad, storeStaffsLoad } from '../../../redux/actions/currentStoreActions';
import { procedurePricingsLoad } from '../../../redux/actions/storeSettings/ProcedurePricingActions';

import { handleStopExamRecording } from '../exam/CommonFunctions';
import { rpaClaimFormLoad } from '../../../redux/actions/addOns/rpa/rpaAction';

class EditOrderButton extends Component {

  loadStoreDependencies(storeId){
    var localStorageStoreId = localStorage.getItem('StoreID')
    if(!localStorageStoreId){
      this.props.dispatch(currentStoreLoad(storeId))
      this.props.dispatch(storeStaffsLoad(storeId,{ rpp: 1000, filter: { doctor: storeId }}))
      this.props.dispatch(procedurePricingsLoad({rpp: 99999, filter: {for_store: storeId}, include:['procedures']}))
    }
    this.props.dispatch(staffsLoad({ rpp: 9999, filter: { selected_store: storeId } }))
  }

  async handleExamStopSaveRecording(exam) {
    const res = await handleStopExamRecording()
    res && this.props.dispatch(examUpdate({ id: exam.id, telemed_visits_attributes: [res] }, { include: '*' }))
  }

  handleOrderEdit(storeId, id){
    const { isTelemedExam }= this.props;
    this.loadStoreDependencies(storeId)

    this.props.dispatch(orderEdit(id));
    this.props.dispatch(tabChange({order: 1}))
    // this.props.dispatch(claimFormLoad(id))
    new Promise((resolve, reject)=>{
      this.props.dispatch(orderLoad(id, {direction: "desc", sort: "created_at", include: '*'}))
      .then((response) => {
        var res = response.value.data.order
        this.handleClaimFormLoad(id, res)
        //Load associated exam
        if(res.exam_examination_id)
          this.props.dispatch(examLoad(res.exam_examination_id, { include: '*' }))
            .then((res) => isTelemedExam && this.handleExamStopSaveRecording(res.value.data.exam))
        //Invoice report template
        this.props.dispatch(patientReportsLoad({patient_id: res.patient_attributes.id, staff_id: res.staff_id, store_id: res.store_attributes.id, filter: {category: "invoice"}}))
      })
    })
    setTimeout(function(){
      $('#editExamForm-modal').modal('hide');
      $('#orderForm-modal').modal('show');
    }, 1000)
  }

  handleClaimFormLoad(id, order) {
    if (order.rpa_claim_exist) {
      this.props.dispatch(rpaClaimFormLoad(id))
    }
    else {
      this.props.dispatch(claimFormLoad(id))
    }
  }

  render() {
    const { orderId, storeId, order } = this.props;
    return (
      <span>
        <span
          className="pointer"
          onClick={this.handleOrderEdit.bind(this, storeId, orderId)}
        >
          {this.props.children}
        </span>
      </span>
    );
  }
}

export default connect()(EditOrderButton);

import React, { Component } from 'react';
export default class InputWithIcon extends Component {

  render() {
    const {
      props,
      props: { input, disabled, placeholder, autoComplete, wrapperClasses, wrapperSize, label, labelClasses, iconClasses, title, titleAppend, shouldRoundDecimalVal },
      props: { meta: { touched, error, warning } }
    } = this;
    return (
      <div className={`${disabled && 'disabled'} ${wrapperClasses}`}>
        {label !== undefined && <label className={labelClasses}>{props.label}</label>}
        <div className={`input-group ${wrapperSize}`}>
          <div className="input-group-prepend">
            { iconClasses !== undefined && <i className={`input-group-text ${iconClasses}`}/> }
            { title !== undefined && <span className="input-group-text">{title}</span> }
          </div>
          <input
            {...input}
            value={shouldRoundDecimalVal ? input.value?.toFixed(shouldRoundDecimalVal) : input.value}
            autoComplete={autoComplete}
            placeholder={placeholder}
            type={props.type}
            disabled={props.disabled || false}
            className={`${props.className}  ${touched && error && 'is-invalid'}`}
          />
          { titleAppend !== undefined && 
            <div className="input-group-append">
              <span className="input-group-text">{titleAppend}</span>
            </div> }
        </div>
        { touched &&
          ((error && <span className="error-msg text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
        {/* { touched && (error && <i className={`la la-warning text-danger ${label === undefined ? 'error-icon' : 'error-icon-label'}`}></i>)} */}
      </div>
    );
  }
}

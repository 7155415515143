import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { submit } from 'redux-form'
import Alert from '../../../../../itrust_common/components/Alert'
import * as $ from 'jquery';
import { rpaClaimFormSubmit } from '../../../../../redux/actions/addOns/rpa/rpaAction'
import { getInsuranceFromRPACompanies } from '../../AddOnsHelperFunctions'

function RpaClaimSubmitButton(props) {
  const { rpaSaving, order, rpaConfig, companies } = props
  const history = useHistory()
  const dispatch = useDispatch()

  const handleRpaClaimSubmit = () => {
    if (rpaConfig?.status !== 'inactive') {
      const insurance = getInsuranceFromRPACompanies(companies, order.patient_insurance_attributes?.insurance_name)
      dispatch(submit("1500ClamForm"))
      setTimeout(() => {
        let invalidInputs = document.getElementsByClassName("is-invalid")
        if (invalidInputs.length > 0) {
          Alert.warning("Please fill the required information.")
        } else {
          dispatch(rpaClaimFormSubmit({ orders_order_id: order.id, patient_id: order.patient_id, insurance_type: order.patient_insurance_attributes?.insurance_type, store_id: order.store_id, insurance_payer: order.patient_insurance_attributes?.insurance_name, service: insurance.service, other_details: { resource: "submit_claim" } }))
        }
      }, 500)
    }
    else {
      history.push('/add-ons/rpa/dashboard')
      $('#orderForm-modal').modal('hide');
    }
  }

  return (
    <>
      <button className="btn btn-outline-primary mr-lg-7 mr-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" onClick={handleRpaClaimSubmit}>
        {rpaSaving ? 'Sending...' : `Send to ${order.patient_insurance_attributes?.insurance_name}`}
      </button>
    </>
  )
}
const mapStateToProps = (state) => {
  const { rpa, organisation: { organisation }, rpa: { companies } } = state

  return {
    rpaSaving: rpa.saving,
    rpaConfig: organisation.add_ons_config?.type?.rpa,
    companies,
  }
}

export default connect(mapStateToProps)(RpaClaimSubmitButton)

import React, { Component } from 'react';
import { change, reduxForm } from 'redux-form';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as $ from 'jquery';

import { Select, DatePicker } from '../../../../itrust_common/components/form';
import { dateFormat, numberToCurrency, formatPhoneNumber, getCustomLabel, humanize, getShortName } from '../../../../itrust_common/components/HelperFunctions';

import { orderEdit, orderLoad, orderUpdate } from '../../../../redux/actions/order/orderActions';
import { claimFormLoad } from '../../../../redux/actions/order/claimFormActions';
import { tabChange } from '../../../../itrust_common/redux/actions/otherActions';
import { required } from '../../../../itrust_common/components/form/FormValidations';
import Alert from '../../../../itrust_common/components/Alert';
import { rpaClaimFormLoad } from '../../../../redux/actions/addOns/rpa/rpaAction';
import OrderInsurancePayer from './OrderInsurancePayer';

let timeout = null;
class OrderHeaderForm extends Component {

  constructor(props) {
    super(props)
    if (!this.props.orderStaffId && this.props.storeDefaultProviderId) {
      this.props.dispatch(orderUpdate({ id: props.order.id, staff_id: props.storeDefaultProviderId }, { include: '*' }))
    }
  }

  handleOrderEdit(id) {
    this.props.dispatch(tabChange({ order: 1 }))
    this.props.dispatch(orderEdit(id));
    this.props.dispatch(orderLoad(id, { include: '*' }));
    // this.props.dispatch(claimFormLoad(id))
    this.handleClaimFormLoad(id)
  }

  async handleClaimTypeChange(claimType) {
    const { dispatch, order } = this.props
    if (claimType) {
      await dispatch(orderUpdate({ id: order.id, claim_type: claimType }, { include: '*' }))
      // dispatch(claimFormLoad(order.id))
      this.handleClaimFormLoad(order.id)
    }
    else {
      Alert.warning("Please select a valid insurance claim type.")
    }
  }

  handleClaimFormLoad(id) {
    const { order } = this.props
    if (order.rpa_claim_exist) {
      this.props.dispatch(rpaClaimFormLoad(id))
    }
    else {
      this.props.dispatch(claimFormLoad(id))
    }
  }

  getClaimTypeDropdownOptions(dropdownValuesObj) {
    return dropdownValuesObj && Object.keys(dropdownValuesObj).filter(key => dropdownValuesObj[key] === true);
  }

  render() {
    const { defaultVisible, handleSubmit, selectedOrderId, providers, allStaff, order, currentUser, handleClaimProviderSubmit, visionInsuranceTemplate, medicalInsuranceTemplate, orderStaffId, activeTab, claimFormData } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="panel-group" id="accordion">
          <div className="panel panel-default">
            <div id="collapseOne" className={`panel-collapse collapse ${defaultVisible ? 'show' : ''}`}>
              <div className="panel-body">
                <div className="bg-white d-flex rounded shadow-sm my-5">
                  <div className="border-right border-light text-center p-5">
                    {getShortName(order.patient_attributes, 'mr-5 width-6 height-6', 'mr-5 width-6 height-6 font-size-40 ')}
                    <h5 className="mt-2 text-nowrap">
                      <Link to={`/patients/${order.patient_attributes.id}/overview`} className="btn-link" onClick={() => $('#orderForm-modal').modal('hide')}>{order.patient_attributes.name}</Link>
                    </h5>
                  </div>
                  <div className="p-lg-6 p-2">
                    <div>
                      <span className="text-nowrap">DOB & Age:</span>
                      <span className="text-muted pl-3 text-nowrap">{dateFormat(order.patient_attributes.date_of_birth)} | {order.patient_attributes.age}</span>
                    </div>
                    <div>
                      <span>Phone:</span>
                      <span className="text-muted text-muted pl-3 text-nowrap">{formatPhoneNumber(order.patient_attributes.cell_phone)}</span>
                    </div>
                    <div>
                      <span>Email:</span>
                      <span className="text-muted text-muted pl-3 text-nowrap">{order.patient_attributes.email}</span>
                    </div>
                    <div>
                      <span className="text-nowrap">{visionInsuranceTemplate && getCustomLabel(visionInsuranceTemplate, 'insurance')}:</span>
                      <span className="text-muted text-nowrap text-muted pl-3 text-nowrap">{order.patient_attributes.patient_vision_insurance && order.patient_attributes.patient_vision_insurance.insurance_name}</span>
                    </div>
                    <div>
                      <span className="text-nowrap">{medicalInsuranceTemplate && getCustomLabel(medicalInsuranceTemplate, 'insurance')} :</span>
                      <span className="text-muted text-nowrap text-muted pl-3 text-nowrap">{order.patient_attributes.patient_medical_insurance && order.patient_attributes.patient_medical_insurance.insurance_name}</span>
                    </div>
                  </div>
                  <div className="border-left border-light p-lg-5 p-2 w-100">
                    <div className="row">
                      <div className="col-8">
                        <div className="row">
                          <Field name="staff_id" label="Created By"
                            component={Select} data={allStaff}
                            placeholder={!orderStaffId && 'Select Provider'}
                            textField="name" valueField="id"
                            wrapperClasses={`${(currentUser.role === 'non_admin' || currentUser.role === 'admin') && 'disabled'} form-group col-2`} />
                          <OrderInsurancePayer claimFormData={claimFormData} dispatch={this.props.dispatch} activeTab={activeTab} />
                          {currentUser.role !== 'non_admin' &&
                            <Field name='order_date' label="Order Date" validate={[required]} component={DatePicker} wrapperClasses='form-group col-lg-3 col-2' />
                          }
                          <div className="col-lg-3 col-2">
                            <div className="form-group">
                              <label htmlFor="orderpleFormControlSelect2">Order Selection </label>
                              <select className="form-control" value={selectedOrderId} id="orderpleFormControlSelect2" onChange={(e) => this.handleOrderEdit(e.target.value)} >
                                {order.all_orders && order.all_orders.map((order) =>
                                  <option value={order.id}>{dateFormat(order.order_date)}</option>
                                )}
                              </select>
                            </div>
                          </div>
                          {claimFormData.id &&
                            <div className="col-4">
                              <div className="form-group">
                                <label htmlFor="orderinsuranceclaimprovider">Insurance Claim Provider</label>
                                <select className="form-control" value={claimFormData.claim_provider_id} id="orderinsuranceclaimprovider" onChange={(e) => handleClaimProviderSubmit({ id: claimFormData.id, claim_provider_id: e.target.value })} >
                                  <option value="" className="text-muted">Select Provider</option>
                                  {providers.map((provider) =>
                                    <option value={provider.id}>{provider.name}</option>
                                  )}
                                </select>
                              </div>
                            </div>
                          }
                          {claimFormData.id &&
                            <div className="col-3">
                              <div className="form-group">
                                <label htmlFor="insuranceClaimType">Insurance Claim Type</label>
                                <select className="form-control" value={order.claim_type} id="insuranceClaimType" onChange={(e) => this.handleClaimTypeChange(e.target.value)} >
                                  <option value="" className="text-muted">Select Claim Type</option>
                                  {this.getClaimTypeDropdownOptions(order.claim_dropdown_values)?.map((claimType) =>
                                    <option value={claimType}>{humanize(claimType)}</option>
                                  )}
                                </select>
                              </div>
                            </div>
                          }
                        </div>
                      </div>

                      <div className="offset-lg-1 col-lg-3 col-4 text-nowrap">
                        <span className="d-block pb-2">Balance Due</span>
                        <div className="bg-light py-3 border-bottom border-bottom-2">
                          <span className="pr-lg-6 pl-lg-6">Total Paid</span> <span className="pl-6">{numberToCurrency(order.amount_paid)}</span>
                        </div>
                        <div className="bg-light py-3">
                          <span className="pr-lg-6 pl-lg-6">Balance due</span> <span className="pl-1">{numberToCurrency(order.balance_due)}</span>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}


OrderHeaderForm = reduxForm({
  form: 'orderHeaderForm',
  enableReinitialize: true,
  onChange: (values, dispatch, props) => {
    if (props.dirty) {
      clearTimeout(timeout);
      timeout = setTimeout(() => props.submit(), 300);
    }
  },
})(OrderHeaderForm)

OrderHeaderForm = connect(state => {
  const { currentStore, order, order: { order: { patient_insurance_attributes } }, staff, patient, auth, setting: { patientTemplate: { patient_template } }, } = state
  const patientInsuranceData = patient_template.personal_information?.insurance_detail?.fields
  const patient_insurance_name = patient_insurance_attributes && patient_insurance_attributes.is_archived && `${patient_insurance_attributes.insurance_name}(Archived)`

  return {
    visionInsuranceTemplate: patientInsuranceData?.vision_insurance?.fields,
    medicalInsuranceTemplate: patientInsuranceData?.medical_insurance?.fields,
    order: order.order,
    patient: patient.patient,
    providers: currentStore.staffs,
    allStaff: staff.staffs,
    selectedOrderId: order.editing && order.editing.id,
    currentUser: auth.user,
    orderStaffId: order.order.staff_id,
    storeDefaultProviderId: currentStore.store.default_provider_id,
    initialValues: {
      id: order.order.id,
      staff_id: order.order.staff_id ? order.order.staff_id : currentStore.store.default_provider_id,
      order_date: order.order.order_date,
      patient_insurance_id: order.order.patient_insurance_id,
      patient_insurance_name: patient_insurance_name
    }
  }
})(OrderHeaderForm)

export default OrderHeaderForm;

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/static/icons/logo.png';
import iTrustLogo from "../../../assets/static/images/itrust-logo.png";
import { AutoComplete } from '../../others';
import { openInNewTab, humanize, wordCount, getShortName } from '../../HelperFunctions';
import { connect } from 'react-redux';
import * as $ from 'jquery'
import { withRouter } from 'react-router-dom'
import Notification from '../../../../containers/authorised/notification/Notification';
import { currentUserEditing } from '../../../redux/actions/authActions';


class Header extends Component {
  render() {
    const { headerMenu, userType, storeSelectionComponent, currentUser, organisation, store, dispatch, activeTourType } = this.props
    return (
      <header className="app-header bg-white w-100 position-fixed border-bottom">
        <div className="d-flex p-5">
          <div className="org-logo-div text-left">
            {process.env.REACT_APP_SUITE_NAME === "OWNER-SUITE" ?
              <img src={logo} alt="logo" className="img-fluid" style={{ height: "3rem" }} /> :
              userType !== "Patient" ?
                <NavLink to="/"><img src={iTrustLogo} alt="iTrust" style={{ maxWidth: "10rem" }} /></NavLink> :
                <NavLink to={`/patients/${currentUser.id}/overview`}>
                  {getShortName(organisation, 'height-3', 'height-3 width-3 font-size-18')}
                </NavLink>
            }
          </div>
          {/* <div className="org-operation-div-d"> */}
          <div className="d-flex justify-content-end align-items-center w-100">
            {(userType === "Staff" || userType === "Patient") &&
              storeSelectionComponent
            }

            <div className="row org-logo-div px-lg-9 px-2">
              <div className="col-12 px-0">
                {searchType(userType, this)}
              </div>
            </div>

            <div className={`profile d-flex flex-row align-items-center justify-content-end`}>
              {currentUser.type !== "Patient" && <Notification />}
              <div className={`px-3 py-2 pointer organisation-menu ${activeTourType === 'activate_account' && 'showDropdown'}`}>
                <a className='d-flex align-items-center' data-toggle="dropdown">
                  <i className="las la-angle-down mr-3"></i>
                  {getShortName(currentUser, 'profile-pic', 'profile-pic font-size-18')}
                </a>
                <div className="dropdown-menu border-0 shadow">
                  {headerMenu.map(function (item) {
                    if (item.title != 'Contact' && item.title != 'Preferences') {
                      return (
                        <NavLink key={item.path} to={item.path} className={`dropdown-item py-4 ${item.title === 'Your Billing' && 'activate-account-step-1'}`}>
                          <i className={`la ${item.icon} pr-2`} />
                          {item.title}
                        </NavLink>)
                    }
                    else if (item.title === 'Preferences') {
                      return (
                        <button className="dropdown-item py-4" onClick={() => { dispatch(currentUserEditing({ preferencesEdit: true })); $('#preferences-modal').modal('show') }}>
                          <i className={`la ${item.icon} pr-2`} />
                          {item.title}
                        </button>)
                    }
                  })
                  }
                  {currentUser.type !== "Patient" &&
                    <a className="dropdown-item py-4" target="_blank" rel="noopener noreferrer" href="https://www.manula.com/manuals/itrust/itrust-optometry-cloud-ehr/2/en/topic/itrust-optometry-ehr-user-manual"><i className="la la-external-link-alt pr-2 pt-3" />User Manual</a>
                  }
                  <button className="dropdown-item py-4" onClick={() => this.props.handleLogout()}><i className="la la-sign-out pr-2 pt-3" />Logout</button>
                </div>
              </div>
              <div className="px-2 text-left">
                <span className="profile-details-section-designation text-nowrap">{currentUser.name}<br />
                  <i className="text-nowrap">{humanize(currentUser.role)}</i></span>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </header>
    );
  }
};

const searchType = (userType, _this) => {
  switch (userType) {
    case 'Staff': return (
      <AutoComplete placeholder="Search by name, DOB(mm/dd/yyyy) or phone" textField="name" valueField="id" fieldName="patient"
        onSelect={(id) => _this.props.history.push(`/patients/${id}`)} klass="Patient" wrapperclassName="search-bar py-1" dropdownClasses="mt-4" />
    )
    case 'User': return (
      <AutoComplete textField="name" valueField="id" fieldName="organisation" onSelect={(id) => openInNewTab(`/organisations/${id}`)} klass="Organisation" wrapperclassName="search-bar py-1" />
    )
    default:
  }
}

const mapStateToProps = state => {
  const { store, auth, organisation, currentStore, onBoarding } = state
  return {
    stores: store.stores,
    store: currentStore && currentStore.store,
    currentUser: auth.user,
    organisation: organisation.organisation,
    activeTourType: onBoarding?.activeTourType,
  }
}
export default connect(mapStateToProps)(withRouter(Header));
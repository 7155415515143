import React, {Component} from 'react';
import { Field, FieldArray, change } from 'redux-form';
import {Checkbox} from '../../../../form/'
import GlassForm from  './GlassForm'
import { handleClearRefractionContactLensFields } from '../../../../HelperFunctions';

class RenderGlass extends Component {
  
  render() {
    const {template, isTemplate, fields, pprops, copyToCategory} = this.props
    return (
      <span>
        {fields.map((member, index, members) => (
          <div className="card-body border-top border-light">
            <div className="d-flex flex-row justify-content-between align-items-center py-3 border-top-2 border-bottom-2 border-light">
              <div>{`Current Glass #${index+1}`}</div>
              <div>
                {
                  !isTemplate && index!==0 &&
                  <button type="button" className="btn btn-danger" aria-label="Close" onClick={() => { pprops.dispatch(change('examRefractionContactLensForm',`${member}[_destroy]`,true));  setTimeout(() => pprops.submit(), 500) }}>
                    Delete
                  </button>
                }
                {
                  !isTemplate && (index === fields.length-1) && 
                  <button 
                    type="button" 
                    className="btn btn-primary ml-7" 
                      onClick={() => { fields.push({ od_sphere: "1", od_cylinder: " ", os_cylinder: " " });  setTimeout(() => pprops.submit(), 500)}}>
                    Add New
                  </button>
                }
                {!isTemplate &&
                  <>
                    <Field name={`${member}[is_marked_complete]`} component={Checkbox} label={members.get(index).is_marked_complete ? <i className="la la-angle-up" /> : <i className="la la-angle-down" />} className="d-none" wrapperClasses="mr-6 d-inline-block checkbox-remove" />
                    {members.get(index).is_marked_complete &&
                    <span className="d-inline-block text-dark" onClick={() => handleClearRefractionContactLensFields(pprops,`current_glasses_attributes[${index}]`)}>
                        <span className="badge badge-pill badge-danger"><i className="las la-trash-alt"></i></span>
                      </span>
                    }
                  </>
                }
              </div>
            </div>
            <div className={members.get(index).is_marked_complete || isTemplate ? 'pt-6': 'd-none'}>
              <GlassForm
                {...this.props}
                name={`current_glasses_attributes[${index}]`}
                baseName={`refraction_contact_lens_exam[visual_acuity][fields][current_glass][tests][0]`}
                fieldValues={template['refraction_contact_lens_exam']['visual_acuity']['fields']['current_glass']['tests'][0]}
                isCurrentGlass={true}
                />
              <div className="d-flex flex-row justify-content-end align-items-center mt-7">
                {
                  !isTemplate &&
                    [ 
                      <button 
                        type="button" 
                        className="btn btn-outline-primary mr-7" 
                        onClick={()=> { copyToCategory(`current_glasses_attributes[${index}]`, "subjective_attributes");  setTimeout(() => pprops.submit(), 500) }}>
                        Copy to Subjective
                      </button>,
                      <button 
                        type="button" 
                        className="btn 
                        btn-outline-primary mr-7" 
                        onClick={()=> { copyToCategory(`current_glasses_attributes[${index}]`, "objective_attributes");  setTimeout(() => pprops.submit(), 500) }}>
                        Copy to Objective
                      </button>
                    ]
                }
                {
                  !isTemplate && index!==0 &&
                  <button type="button" className="btn btn-danger" aria-label="Close" onClick={() => { pprops.dispatch(change('examRefractionContactLensForm',`${member}[_destroy]`,true));  setTimeout(() => pprops.submit(), 500) }}>
                    Delete
                  </button>
                }
                
              </div>
            </div>
          </div>
        ))}
      </span>
    );
  }
}

const CurrentGlass = props => {
  const {template, isTemplate, pprops, copyToCategory} = props
  const name = isTemplate ?  "refraction_contact_lens_exam[visual_acuity][fields][current_glass][tests]" : "current_glasses_attributes"
  return (
    <FieldArray name={name}
      props = {{
        template: template,
        isTemplate: isTemplate,
        pprops: pprops,
        copyToCategory: copyToCategory
      }} 
      component={RenderGlass} />
  );
}

export default CurrentGlass;
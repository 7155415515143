import React, { useEffect, useRef, useState } from 'react'
import { DIALER_BUTTONS } from '../../../../../../itrust_common/components/Constants';

export default function Dialer(props) {
  const { makeOutGoingCall, isCallActive } = props
  const [phoneNumber, setPhoneNumber] = useState('+1')
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, [])

  const onChangePhoneNumber = (val, type) => {
    if (type === 'dialer')
      setPhoneNumber(phoneNumber + val)
    else
      setPhoneNumber(val)
  }

  const handleMakeOutGoingCall = () => {
    makeOutGoingCall(phoneNumber)
  }

  const handleClearPhoneNumber = () => {
    setPhoneNumber(phoneNumber.slice(0, -1))
  }

  return (
    <>
      <div className='text-center pt-5'>
        <input type='text' value={phoneNumber} ref={inputRef} onChange={(e) => onChangePhoneNumber(e.target.value)} className='border-0 text-center font-weight-500 font-size-20' />
      </div>
      <div className='d-flex flex-wrap justify-content-center align-items-center mt-6 dialer-width'>
        {DIALER_BUTTONS.map((item) => <button key={item.number} className='rounded-circle dialer-btn btn mb-5 mx-5 d-flex flex-column justify-content-center align-items-center' onClick={() => onChangePhoneNumber(item.number, 'dialer')}>
          <span className='font-weight-500 font-size-20'>{item.number}</span>
          <span className='font-weight-500 font-size-12'>{item.alphabet}</span>
        </button>)}
      </div>
      <div className='d-flex justify-content-center align-items-center py-6'>
        <button className={`dialer-btn rounded-circle bg-primary border-0  ml-10 font-size-24 ${isCallActive && 'disabled'}`} onClick={handleMakeOutGoingCall}>
          <i className="las la-phone text-light"></i>
        </button>
        <button className='border-0 p-0 bg-transparent d-flex ml-9' onClick={handleClearPhoneNumber}>
          <span className='left-arrow'></span>
          <span className='d-flex align-items-center pr-2 pl-0 font-size-12 bg-light-1'>X</span>
        </button>
      </div>
    </>
  );
};
import { isOrgNotExist } from "../../actions/public/OrganisationActions";
const parent = 'PUBLIC_'

const DEFAULT_STATE = {
  current_organisation: {},
  isOrgNotExist:false,
}
let changes = null;
export default function organisationReducer(state = DEFAULT_STATE, action){
  const response = action.payload && (action.payload.data || action.payload.response)
  switch(action.type){
    case `${parent}ORGANISATION_LOAD_PENDING`:
      // Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      return {...state};

    case `${parent}ORGANISATION_LOAD_REJECTED`:
    case `${parent}ORGANISATION_VALIDATE_REJECTED`:
      // Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        isOrgNotExist: isOrgNotExist(response.data)
      }
      return {...state, ...changes};

    case `${parent}ORGANISATION_LOAD_FULFILLED`:
      changes = {
        ...response,
      };
      return {...state, ...changes};
    
    default:
      return state;
  }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import bitmap from '../../../assets/static/images/bitmap.png'
import { dateTimeFormat, getShortName, humanize } from '../../../itrust_common/components/HelperFunctions';
import { logout } from '../../../itrust_common/redux/actions/authActions';

class PatientSignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: props.currentUser.has_scheduled_appointment
    }
  }

  handleDoLater() {
    this.props.dispatch(logout())
  }

  handleGetWizardSteps() {
    const { patient: { signup_steps } } = this.props
    let completedCount = 0;
    for (const step in signup_steps) {
      if (signup_steps.hasOwnProperty(step) && signup_steps[step]) {
        completedCount++;
      }
    }
    return 5 - completedCount;
  }

  render() {
    const { currentUser, meta, organisation, patient: { signup_steps } } = this.props
    const { isVisible } = this.state
    return (
      <div className={`modal bg-light ${!meta.one_click_login && isVisible && !signup_steps?.completed && 'd-block'}`} style={{ zIndex: 999999999 }} id="patient-signup-modal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog mx-auto mt-9 pt-9" role="document">
          <div className="modal-content shadow-lg">
            <div className="modal-header flex-column bg-info-light text-center align-items-center">
              <span className="d-block badge py-3">
                {getShortName(organisation, 'width-6', 'width-6 height-6 font-size-40')}
              </span>
            </div>
            <div className="modal-body p-6">
              <div className="d-flex align-items-center mb-5">
                <img src={bitmap}></img>
                <h6 className="pl-5">{humanize(currentUser.name)}</h6>
              </div>
              <span>
                You have <strong>{this.handleGetWizardSteps()}</strong> steps left to complete your sign up for appointment on {dateTimeFormat(currentUser.nearest_future_appointment)}.
              </span>
              <div className="text-right pt-7">
                <Link exact to={`/patients/${currentUser.id}/account_information`} className="btn btn-dark ml-5" onClick={() => this.setState({ isVisible: false })}>Continue Now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(PatientSignUp);
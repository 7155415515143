import React from 'react'
import { dateFormat, timeFormat } from '../../../../../../../itrust_common/components/HelperFunctions'
import { Link } from 'react-router-dom'
import { getCallStatusSymbol, getCallStatus, getPatientPhoneNumber } from '../../../../AddOnsHelperFunctions'

export default function Log(props) {
  const { callLog } = props

  return (
    <>
      <div className='d-flex justify-content-between text-secondary font-size-14 my-3'>
        <span className='d-flex align-items-basl'>
          <span className={`text-primary mr-2`}>
            {getCallStatusSymbol(callLog)}
          </span>
          <span className='d-flex flex-column'>
            {callLog.patient_attributes ?
              <Link to={`/patients/${callLog.patient_attributes.id}/overview`} target='_blank' className="text-primary">{callLog.patient_attributes.name}</Link> :
              <span className='text-primary'>Anonymous</span>
            }
            {getPatientPhoneNumber(callLog)}
            <div className='d-flex'>
              {getCallStatus(callLog)}
              <span className='ml-3'>{callLog.duration && `(${callLog.duration} sec)`}</span>
            </div>
          </span>
        </span>
        <span className='d-flex flex-column align-items-end'>
          <span>{dateFormat(callLog.call_time)}</span>
          <span>{timeFormat(callLog.call_time)}</span>
        </span>
      </div>
    </>
  )
}
import Toastr from "../../../../itrust_common/components/Toastr"

const DEFAULT_STATE = {
  call_logs: [],
  phone_report: {},
  saving: false,
}
let changes = null

export const voipReportReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    case 'VOIP_CALL_LOGS_LOAD_PENDING':
    case 'VOIP_PHONE_REPORT_LOAD_PENDING':
      return { ...state, saving: true };

    case 'VOIP_CALL_LOGS_LOAD_REJECTED':
    case 'VOIP_PHONE_REPORT_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'VOIP_CALL_LOGS_LOAD_FULFILLED':
      changes = {
        call_logs: response['add_ons/voip/call_logs'],
        meta: response.meta,
        saving: false,
      }
      return { ...state, ...changes };

    case 'VOIP_PHONE_REPORT_LOAD_FULFILLED':
      changes = { phone_report: { ...response.records }, saving: false }
      return { ...state, ...changes };

    default:
      return state
  }
}
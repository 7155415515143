import { getInsuranceType } from "../../../../itrust_common/components/HelperFunctions";
import Toastr from "../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  saving: false,
  verify: false,
  detailViewPopup: { visible: false },
  rpa_claim_info: {},
  eligibility: {},
  companies: [],
  company: {},
  rpa_logs: [],
  editing: {},
  graph_data: {},
  kpi_data: {},
  rpaClaimForm: {},
}

let changes = null

export const rpaReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)

  switch (action.type) {

    case 'RPA_COMPANIES_LOAD_PENDING':
    case 'RPA_COMPANY_UPDATE_PENDING':
    case 'RPA_CLAIM_FORM_SUBMIT_PENDING':
    case 'RPA_CLAIM_FORM_CREATE_PENDING':
    case 'RPA_CLAIM_SUBMIT_LOGS_LOAD_PENDING':
    case 'RPA_DASHBOARD_LOAD_PENDING':
    case 'RPA_CLAIM_FORM_UPDATE_PENDING':
      return { ...state, saving: true };

    case 'RPA_COMPANIES_LOAD_REJECTED':
    case 'RPA_COMPANY_UPDATE_REJECTED':
    case 'RPA_CLAIM_FORM_SUBMIT_REJECTED':
    case 'RPA_CLAIM_FORM_CREATE_REJECTED':
    case 'RPA_CLAIM_SUBMIT_LOGS_LOAD_REJECTED':
    case 'RPA_DASHBOARD_LOAD_REJECTED':
    case 'RPA_CLAIM_FORM_LOAD_REJECTED':
    case 'RPA_CLAIM_FORM_UPDATE_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false }
      return { ...state, ...changes };

    case 'RPA_CLAIM_FORM_SUBMIT_FULFILLED':
    case 'RPA_COMPANY_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { ...response.add_on.config, saving: false }
      return { ...state, ...changes };

    case 'RPA_CLAIM_FORM_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { rpa_claim_info: { ...response.rpa_log }, meta: { ...response.meta }, saving: false }
      return { ...state, ...changes };

    case 'RPA_COMPANIES_LOAD_FULFILLED':
      changes = { ...response.add_ons[0].config, rpa_addon_id: response.add_ons[0].id, saving: false }
      return { ...state, ...changes };

    case 'RPA_CLAIM_FORM_LOAD_FULFILLED':
      changes = { rpaClaimForm: { ...response.insurance_claim_form }, saving: false }
      return { ...state, ...changes };

    case 'RPA_CLAIM_FORM_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { rpaClaimForm: { ...response.insurance_claim_form }, saving: false }
      return { ...state, ...changes };

    case 'RPA_CLAIM_SUBMIT_LOGS_LOAD_FULFILLED':
      changes = { ...response, saving: false }
      return { ...state, ...changes };

    case 'RPA_DASHBOARD_LOAD_FULFILLED':
      changes = { [`${response.meta.params}_data`]: { ...response }, meta: { ...response.meta }, saving: false }
      return { ...state, ...changes };

    case 'RPA_EDIT':
      changes = { editing: { ...state.editing, ...action.data } }
      return { ...state, ...changes };

    case 'RPA_EDIT_RESET':
      changes = { editing: {} }
      return { ...state, ...changes };


    // verify
    case 'RPA_ELIGIBILITY_VERIFY_PENDING':
      return { ...state, verify: true };

    case 'RPA_ELIGIBILITY_VERIFY_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      if (response.data.status_code !== 500) {
        changes = { verify: false }
      }
      else {
        changes = { verify: false, eligibility: { ...state.eligibility, [getInsuranceType(response.data.insurance_type)]: { ...response.data } } }
      }
      return { ...state, ...changes };

    case 'RPA_ELIGIBILITY_VERIFY_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { verify: false, eligibility: { ...state.eligibility, [getInsuranceType(response.rpa_log.insurance_type)]: { ...response.rpa_log, success: true } } }
      return { ...state, ...changes };

    case 'RPA_DETAIL_VIEW':
      changes = { detailViewPopup: { ...state.detailViewPopup, ...action.data } }
      return { ...state, ...changes };

    case 'RPA_ELIGIBILITY_EDIT':
      changes = { eligibility: { ...state.eligibility, ...action.data } }
      return { ...state, ...changes };

    case 'RPA_ELIGIBILITY_RESET':
      changes = { eligibility: {} }
      return { ...state, ...changes };

    case 'RPA_CLAIM_FORM_RESET':
      changes = { rpaClaimForm: {} }
      return { ...state, ...changes };

    default:
      return state
  }
}
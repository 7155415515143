import React from 'react'
import { dateFormat } from '../../../../../../itrust_common/components/HelperFunctions'
import { timeFormat } from '../../../../../../itrust_common/components/HelperFunctions'
import NoCallLogsFoundImg from "../../../../../../assets/static/images/no-call-logs-found.svg";
import { getCallStatusSymbol, getCallStatus } from '../../../AddOnsHelperFunctions';

export default function PatientLogs(props) {
  const { callLogs } = props

  return (
    <>
      <div className='calling-inner-height-2 overflow-auto p-2 mx-2 my-4'>
        {callLogs?.length === 0 ? <div className='text-center'>
          <img src={NoCallLogsFoundImg} />
        </div> :
          <>
            {callLogs?.map((call_log) => <>
              {call_log.status &&
                <div className='d-flex justify-content-between text-secondary font-size-14 mb-2'>
                  <span className='d-flex'>
                    <span className={`text-primary mr-2`}>
                      {getCallStatusSymbol(call_log)}
                    </span>
                    <span className='d-flex flex-column'>
                      {getCallStatus(call_log)}
                      <span>{call_log.duration && `${call_log.duration} sec`} </span>
                    </span>
                  </span>
                  <span className='d-flex flex-column align-items-end'>
                    <span>{dateFormat(call_log.call_time)}</span>
                    <span>{timeFormat(call_log.call_time)}</span>
                  </span>
                </div>
              }
            </>)}
          </>
        }
      </div>
    </>
  )

}
import React from 'react'
import WhatsAppFeaturesImg from '../../../../assets/static/images/whatsapp-features.svg'
import WhatsAppImg from '../../../../assets/static/images/whatsapp-adv.svg'
import AdvertisementWrap from '../AdvertisementWrap'

export default function WhatsAppAdvertisement(props) {
  const { isPopupScreen } = props

  const whatsAppFeature = [
    "Connect with patients via WhatsApp for timely reminders and personalized messages, boosting engagement.",
    'Automate routine communications, freeing up staff to focus on patient care and improving efficiency.',
    "Ensure patient information remains private with WhatsApp's end-to-end encryption, meeting data protection standards.",
    'Facilitate real-time, two-way communication with patients, allowing for quick responses to inquiries and improved patient support.'
  ]

  const whatsAppAdvDetail = {
    title: 'WhatsApp',
    redirectTo: 'https://www.itrust.io',
    imageAddOn: WhatsAppImg,
    isPopupScreenAddOnImg: WhatsAppImg,
    imageFeatures: WhatsAppFeaturesImg,
    features: whatsAppFeature
  }

  return (
    <AdvertisementWrap addOnAdvDetail={whatsAppAdvDetail} isPopupScreen={isPopupScreen} />
  )
}
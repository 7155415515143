import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { rpaDetailView } from '../../../../../redux/actions/addOns/rpa/rpaAction';

export default function VerifiedInfoView(props) {
  const { rpaDetailViewPopup } = props
  let defaultWidth = 160;
  let zoomIntervals = 20;
  const dispatch = useDispatch()
  const [imageWidth, setImageWidth] = useState(defaultWidth);
  const [zoomStatus, setZoomStatus] = useState('zoom-in')

  useEffect(() => {
    handleDraggableContainer()
  }, [])

  const handleDraggableContainer = () => {
    const container = document.querySelector(".draggable-container-1");
    const container1 = document.querySelector(".draggable-container");
    function onMouseDrag({ movementX, movementY }) {
      let getContainerStyle = window.getComputedStyle(container1);
      let leftValue = parseInt(getContainerStyle.left);
      let topValue = parseInt(getContainerStyle.top);
      container1.style.left = `${leftValue + movementX}px`;
      container1.style.top = `${topValue + movementY}px`;
    }
    container.addEventListener("mousedown", (e) => {
      container.addEventListener("mousemove", onMouseDrag);
    });
    document.addEventListener("mouseup", () => {
      container.removeEventListener("mousemove", onMouseDrag);
    });
  }

  const handleCloseRpaDetailView = () => {
    dispatch(rpaDetailView({ visible: false }))
  }

  const handleZoomInOut = () => {
    setImageWidth((prev) => {
      let newWidth;
      if (zoomStatus === 'zoom-in' && prev < 200) {
        newWidth = prev + zoomIntervals;
        if (newWidth >= 200) setZoomStatus('zoom-out');
      } else if (zoomStatus === 'zoom-out' && prev > 10) {
        newWidth = prev - zoomIntervals;
        if (newWidth <= defaultWidth) setZoomStatus('zoom-in');
      } else {
        newWidth = prev;
      }
      return newWidth;
    });
  };

  const reset = () => {
    imageWidth !== defaultWidth && setImageWidth(defaultWidth)
    setZoomStatus('zoom-in')
  }

  return (
    <div className='draggable-container bg-white border border-radius-16 shadow pb-5'>
      <div className='font-size-24 px-3 bg-primary text-white draggable-container-1 d-flex justify-content-between align-items-center py-4 draggable-container-header'>
        <span className='font-size-18 ml-3'>
          Click here to move!
        </span>
        <div className='d-flex align-items-center mr-3'>
          {imageWidth !== defaultWidth && <button className='btn btn-primary mr-4 border btn-sm' onClick={reset}>Reset</button>}
          {rpaDetailViewPopup.file_url && <a href={`${rpaDetailViewPopup.file_url}`} target='_blank' className='btn btn-primary mr-4 border btn-sm' onClick={reset}>Open in new window</a>}
          <span className='pointer' onClick={handleCloseRpaDetailView}><i className="las la-times-circle ml-3"></i></span>
        </div>
      </div>
      <div className={`py-5 px-4 d-flex align-items-center ${imageWidth === defaultWidth && 'justify-content-center'}`} onClick={handleZoomInOut}>
        {rpaDetailViewPopup.file_url ? <img src={rpaDetailViewPopup.file_url} style={{ width: `${imageWidth}%`, cursor: zoomStatus }} /> : <p className='text-center font-size-24'>Not Found</p>}
      </div>
    </div>
  )
}

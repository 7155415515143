import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery';
import { withRouter } from 'react-router-dom';
import Joyride from 'react-joyride';
import CustomToolTip from './CustomToolTip';
import GuideModal from './appGuideModal/GuideModal';
import { getCurrentTourSteps, handleCloseCurrentModule, handleOnboardingTourStart, handleActiveTourOverlayArea, handleStartNewModule, handleTourBackStep, handleUserOnBoardingGuideUpdate } from './onBoardingHelpers';
import ProgressPopUp from './progressPopUp/ProgressPopUp';
import { isDirty } from 'redux-form';
import { useOnboardingTourData } from './OnBoardingCustomHooks';
import { onBoardingTourProgressInfoUpdate } from '../../../redux/actions/onBoardingActions';

const OnBoardingTour = (props) => {
  const { organisation, isPurposeFormDirty } = props
  const onBoardingTourData = useOnboardingTourData()
  const { onBoarding: { activeTourType, stepIndex, isTourActive, nextModuleToPlay, moduleTourCompleted, progressPopUpInfo: { checkListShow } }, userOnboardingGuide, userRole, userId } = onBoardingTourData

  useEffect(() => {
    if (organisation.id && userOnboardingGuide !== null && (userOnboardingGuide && !userOnboardingGuide.is_tour_taken_once)) {
      $('#onBoardingModal').modal('show');
    }
    if (organisation.id && organisation.status === 'trial') {
      onBoardingTourData.dispatch(onBoardingTourProgressInfoUpdate({ checkListShow: true, moduleProgressHide: true }))
    }
  }, [organisation.id, userId])

  useEffect(() => {
    moduleTourCompleted && handleUserOnBoardingGuideUpdate(onBoardingTourData)
  }, [moduleTourCompleted])

  useEffect(() => {
    handleStartNewModule(onBoardingTourData)
  }, [nextModuleToPlay])

  const handleJoyrideCallback = (data) => {
    const { action } = data;
    handleActiveTourOverlayArea()
    if (action === 'close' || action === "stop") {
      handleCloseCurrentModule(onBoardingTourData)
    }
    else if (action === 'prev') {
      handleTourBackStep(onBoardingTourData, data)
    }
  };

  const handleTourStart = async () => {
    $('#onBoardingModal').modal('hide');
    handleOnboardingTourStart(onBoardingTourData)
  }

  return (
    <>
      <Joyride
        tooltipComponent={ProgressPopUp}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        disableOverlay={true}
        steps={[{ target: '.onboarding-process-popup', disableBeacon: true, spotlightClicks: false }]}
        run={checkListShow}
      />
      <Joyride
        steps={getCurrentTourSteps(activeTourType)}
        callback={handleJoyrideCallback}
        tooltipComponent={CustomToolTip}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        scrollDuration={2000}
        stepIndex={stepIndex}
        run={isTourActive}
      />
      {/* null denotes old staffs */}
      {(organisation.id && userOnboardingGuide !== null && (userOnboardingGuide && !userOnboardingGuide.is_tour_taken_once)) && <GuideModal handleTourStart={handleTourStart} purposeForUseItrust={organisation.utilization_purpose} userRole={userRole} isPurposeFormDirty={isPurposeFormDirty} />}
    </>
  );
};

const mapStateToProps = (state) => {
  const { organisation: { organisation } } = state

  return {
    organisation,
    isPurposeFormDirty: isDirty('purposeForUseItrustForm')(state),
  }
}

export default connect(mapStateToProps)(withRouter(OnBoardingTour))